<app-step-bar [step]="currentPage"></app-step-bar>

<!-- Encart -->
<div class="content-right">
    <div class="encart" id="info_droite">
        <h4 class="encart-title">Étape 4 </h4>
        <hr>

        <h4>
            {{ isAccountCreation ? "Création de votre espace personnel" : "Identifiez-vous" }}
        </h4>

        <p>Toutes les notifications concernant l’avancement du dossier seront envoyées à l’adresse mail renseignée : soit à l'abonné directement, soit uniquement au représentant s'il y en a un.</p>
        <p>Celle-ci servira également d’identifiant vous permettant d’accéder à votre espace personnel.</p>
        <p>Vous disposez à tout moment d’un droit de rectification et/ou de suppression de vos données personnelles</p>
    </div>
</div>

@if(isAccountCreation)
{
    <div class="tab tab4 form-group row" [formGroup]="form4">
        <div class="content col-12">
            <div class="content-left order-1 col-6">
                <div class="d-flex flex-column">
                    <div>
                        <h4>Je suis : </h4>
        
                        <br>
                    </div>
    
                    <div class="d-flex flex-row">
                        <label class="checkbox-container bold">
                            <input 
                                type="radio" 
                                formControlName="isAbo" 
                                id="profil_abo_repr0" 
                                value="0" >&nbsp;l'abonné(e) concerné(e) par le litige
                            <span class="checkmark"></span>
                        </label>
                        
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            
                        <label class="checkbox-container bold">
                            <input 
                                type="radio" 
                                formControlName="isAbo" 
                                id="profil_abo_repr1" 
                                value="1">&nbsp;le représentant de l'abonné(e)
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </div>
    
                
                <section id="representant">
                    <p class="h4 red" *ngIf="form4.get('isAbo')?.value == '1'">Seul le représentant recevra des messages de la Médiation de l'eau</p>
    
                    <h5 class="font-weight-bold">Informations du représentant</h5>
    
                    <div class="margin8px">
                        <label for="societe_abo">
                            @if(form4.get("isAbo")?.value == "0")
                            {
                                Nom de votre société ou organisme (si vous êtes une personne morale) 
                            }
                            @else
                            {
                                Société ou Organisme : 
                            }
                        </label>
                        <div>
                            <input 
                                class="form-control" 
                                type="text" 
                                name="societe" 
                                id="societe" 
                                formControlName="societe_organisme" 
                                oninput="this.value = this.value.toUpperCase()" 
                                [maxLength]="inputLimit.get('societe_organisme')"
                                [ngClass]="form4.get('societe_organisme')?.value.length >= inputLimit.get('societe_organisme') ? 'red': ''"/>
                        </div>
                    </div>
    
                    <div class="margin8px">
                        <label for="civilite">Civilité : <span class="obligatoire bold">*</span></label>
                        <div>
                            <select name="civilite" id="civilite" class="input_obligatoire text-dark" formControlName="civilite">
                                <option 
                                    [value]="item.ID_CIVILITE" 
                                    *ngFor="let item of listeCivilite">
                                    {{ item.CIVILITE }}
                                </option>
                            </select>
                            <em *ngIf="isInvalid('civilite') || (isAccountFormSubmitted && form4.get('civilite')?.value == 0)">
                                <p>* Champ obligatoire</p>
                            </em>
                        </div>
                    </div>
        
                    <div class="margin8px">
                        <label for="nom">Nom :  <span class="obligatoire bold">*</span></label>
                        <div>
                            <input 
                                type="text" 
                                name="nom" 
                                id="nom" 
                                class="input_obligatoire form-control" 
                                formControlName="nom" 
                                oninput="this.value = this.value.toUpperCase()"
                                [maxLength]="inputLimit.get('nom')" 
                                [ngClass]="form4.get('nom')?.value.length >= inputLimit.get('nom') ? 'red': ''"/>
                        </div>
                        <em *ngIf="isInvalid('nom') && form4.get('nom')?.errors">
                            <p>* Champ obligatoire</p>
                        </em>
                    </div>
        
                    <div class="margin8px">
                        <label for="prenom">Prénom :  <span class="obligatoire bold">*</span></label>
                        <div>
                            <input 
                                type="text" 
                                name="prenom" 
                                id="prenom" 
                                class="input_obligatoire form-control" 
                                formControlName="prenom" 
                                [maxLength]="inputLimit.get('prenom')"
                                [ngClass]="form4.get('prenom')?.value.length >= inputLimit.get('prenom') ? 'red': ''" 
                                (keyup)="eachWordUpperCase(form4.get('prenom')?.value, 'prenom', form4)"/>
                        </div>
                        <em *ngIf="isInvalid('prenom') && form4.get('prenom')?.errors">
                            <p>* Champ obligatoire</p>
                        </em>
                    </div>
                    
                    <div class="margin8px">
                        <label for="email">Email :  <span class="obligatoire bold">*</span></label>
                        <div class="d-flex">
                            <input 
                                type="email" 
                                name="email" 
                                id="email" 
                                class="input_obligatoire form-control input_mail" 
                                formControlName="email"
                                [maxLength]="inputLimit.get('email')"
                                [ngClass]="form4.get('email')?.value.length >= inputLimit.get('email') ? 'red': ''"/>
                            <fa-icon 
                                [icon]="close" 
                                class="fa-icon-close" 
                                size="sm" 
                                title="Modifier les adresses email" 
                                (click)="enableSpecificControl(EMAIL_INCLUSION);resetButtonState()"
                                *ngIf="isButtonVisible">
                            </fa-icon>
                        </div>
                        <em *ngIf="isInvalid('email') && form4.get('email')?.value?.length == 0">
                            <p>* Champ obligatoire</p>
                        </em>
                        <em *ngIf="isInvalid('email') && form4.get('email')?.errors?.['pattern']">
                            <p>* Adresse Email non valide</p>
                        </em>
                        <em *ngIf="isCheckEmail && isEmailExist && isActive">
                            <p>
                                * Cette adresse mail est déjà liée à un espace personnel. 
                                <br> 
                                Veuillez vous connecter à cet espace via ce lien 
                                <a href="https://preprod.abonnes.mediation-eau.fr/" target="_blank">abonnes.mediation-eau.fr</a> 
                            </p>
                        </em>
                        <em *ngIf="isCheckEmail && isEmailExist && !isActive">
                            <p>
                                * Cette adresse mail est déjà liée à un espace personnel mais n'a pas encore été activée.
                                <br>
                                Veuillez cliquer sur ce lien pour recevoir un mail vous permettant d'
                                <span class="cursor text-primary" (click)="resendMail(form4)">activer votre espace personnel</span>
                            </p>
                        </em>
                    </div>
    
                    <div class="margin8px">
                        <label for="email">Confirmer l'adresse email :  <span class="obligatoire bold">*</span></label>
                        <div class="d-flex">
                            <input 
                                type="email" 
                                name="email_confirm" 
                                id="email_confirm" 
                                class="input_obligatoire form-control input_mail" 
                                formControlName="email_confirm"
                                [maxLength]="inputLimit.get('email')"
                                [ngClass]="form4.get('email')?.value.length >= inputLimit.get('email') ? 'red': ''"/>
                            <fa-icon 
                                [icon]="close" 
                                class="fa-icon-close" 
                                size="sm" 
                                title="Modifier les adresses email" 
                                (click)="enableSpecificControl(EMAIL_INCLUSION);resetButtonState()"
                                *ngIf="isButtonVisible">
                            </fa-icon>
                        </div>
                        <em *ngIf="isInvalid('email_confirm') && form4.get('email_confirm')?.value?.length == 0">
                            <p>* Champ obligatoire</p>
                        </em>
                        <em *ngIf="isInvalid('email_confirm') && form4.get('email_confirm')?.errors?.['pattern']">
                            <p>* Adresse Email non valide</p>
                        </em>
                        <em *ngIf="isCheckEmail && form4.hasError('emailMismatch')">
                            <p>* Les adresses email ne sont pas identiques</p>
                        </em>
                    </div>
    
                    <hr>
                </section>
            </div>
        </div>
    </div>
     
    <div class="nav-buttons align-self-center" id="buttons">
        <button type="button" id="prevBtn" (click)="saveFormAndNavigate(-1)">Précédent</button>

            <input 
                type="button" 
                class="submitBtn"
                value="Vérification de l'email" 
                (click)="checkEmail(form4.get('email')?.value)"
                [ngClass]="isFormValid() ? '' : 'disabled' "
                [disabled]="isFormValid() ? '' : 'disabled' "
                *ngIf="isValidateEmailButtonVisible"/>

            <input  
                type="button" 
                value="Activer mon espace personnel" 
                class="submitBtn"
                (click)="createAccount()"
                [ngClass]="isFormValid() ? '' : 'disabled' "
                [disabled]="isFormValid() ? '' : 'disabled' "
                *ngIf="isButtonVisible"/>

        <!-- <button type="button" (click)="populateForm()">TEST</button> -->
    </div>
}
@else
{
    <div class="tab tab4 form-group row" [formGroup]="form4">
        <div class="content col-12">
            <div class="content-left order-1 col-6">
                <section id="abonne">
                    <p class="h4 red">Seul le représentant recevra des messages de la Médiation de l'eau</p>

                    <h5 class="font-weight-bold">Informations de l'abonné</h5>
            
                    <div class="form-group type_rep margin8px">
                        <label for="id_type_rep">Lien du représentant avec l'abonné(e) : <span class="obligatoire bold">*</span></label>
                        <div>
                            <select class="cond_obligatoire input_obligatoire_abo text-dark" name="id_type_rep" id="id_type_rep" (change)="'form-control cond_obligatoire input_obligatoire_abo'" formControlName="lien_abo">
                                <option 
                                    [value]="item.ID_TYPE_REPRESENTANT" 
                                    *ngFor="let item of list_lien_abonne"
                                    [ngClass]="item.ORDRE == 1 ? 'red': ''"
                                    [disabled]="item.ID_TYPE_REPRESENTANT == -1">
                                    {{ item.TYPE_REPRESENTANT }}
                                </option>
                            </select>
                        </div>
                        <em *ngIf="isSubmitted && isInvalid('lien_abo') && form4.get('lien_abo')?.value == 0">
                            <p>* Champ obligatoire</p>
                        </em>
                    </div>
            
                    <div class="margin8px">
                        <label for="societe">Société ou Organisme : </label>
                        <div>
                            <input 
                                class="form-control" 
                                type="text" 
                                name="societe_organisme_abo" 
                                id="societe_organisme_abo" 
                                formControlName="societe_organisme_abo"
                                oninput="this.value = this.value.toUpperCase()"/>
                        </div>
                    </div>
            
                    <div class="margin8px">
                        <label for="civilite">Civilité de l'abonné(e) : <span class="obligatoire bold">*</span></label>
                        <div>
                            <select name="civilite_abo" id="civilite_abo" class="input_obligatoire text-dark" formControlName="civilite_abo" [ngClass]="isAccountCreation ? 'bg-white': ''">
                                <option 
                                    [value]="item.ID_CIVILITE" 
                                    *ngFor="let item of listeCivilite">
                                    {{ item.CIVILITE }}
                                </option>
                            </select>
                            <em *ngIf="isSubmitted && isInvalid('civilite_abo') && form4.get('civilite_abo')?.value == 0">
                                <p>* Champ obligatoire</p>
                            </em>
                        </div>
                    </div>
            
                    <div class="margin8px">
                        <label for="nom_abo">Nom de l'abonné(e) : <span class="obligatoire bold">*</span></label>
                        <div>
                            <input 
                                type="text" 
                                name="nom_abo" 
                                id="nom_abo" 
                                class="input_obligatoire_abo form-control"
                                formControlName="nom_abo" 
                                oninput="this.value = this.value.toUpperCase()"/>
                        </div>
                        <em *ngIf="isSubmitted && isInvalid('nom_abo') && form4.get('nom_abo')?.errors">
                            <p>* Champ obligatoire</p>
                        </em>
                    </div>
            
                    <div class="margin8px">
                        <label for="prenom_abo">Prénom de l'abonné(e) : <span class="obligatoire bold">*</span></label>
                        <div>
                            <input 
                                type="text" 
                                name="prenom_abo" 
                                id="prenom_abo" 
                                class="input_obligatoire_abo form-control"
                                formControlName="prenom_abo" 
                                (keyup)="eachWordUpperCase(form4.get('prenom_abo')?.value, 'prenom_abo', form4)"/>
                        </div>
                        <em *ngIf="isSubmitted && isInvalid('prenom_abo') && form4.get('prenom_abo')?.errors">
                            <p>* Champ obligatoire</p>
                        </em>
                    </div>
            
                    <div class="margin8px">
                        <label for="email_abo">Email de l'abonné(e) (L'abonné(e) ne recevra aucune communication de la part de la Médiation) : </label>
                        <div class="d-flex">
                            <input 
                                type="email" 
                                name="email_abo" 
                                id="email_abo" 
                                class="input_mail form-control"
                                formControlName="email_abo"/>
                            <fa-icon 
                                [icon]="close" 
                                class="fa-icon-close" 
                                size="sm" 
                                title="Modifier les adresses email" 
                                (click)="enableSpecificControl(EMAIL_INCLUSION);resetButtonStatePart2()"
                                *ngIf="isButtonPart2Visible">
                            </fa-icon>
                        </div>
                        <em *ngIf="isSubmitted && isInvalid('email_abo') && form4.get('email_abo')?.value?.length == 0">
                            <p>* Champ obligatoire</p>
                        </em>
                        <em *ngIf="isSubmitted && form4.get('email_abo')?.errors?.['pattern']">
                            <p>* Adresse Email non valide</p>
                        </em>
                        <em *ngIf="form4.hasError('sameEmailMismatch')">
                            <p>* Votre adresse email et celle de votre représentant doivent être différentes. </p>
                            <p>Pour toutes demande d'informations, veuillez nous laissez un message via le <a class="text-bold" href="{{lien_page_contact}}" target="_blank">formulaire de contact</a> sur le site de la Médiation de l'eau.</p>
                        </em>
                    </div>
            
                    @if(form4.get("email_abo")?.value.length > 0)
                    {
                        <div class="margin8px" [ngClass]="form4.get('email_abo')?.value.length == 0 ? 'd-none': ''">
                            <label for="email_abo_confirm">Confirmer l'adresse email : <span class="obligatoire bold">*</span></label>
                            <div class="d-flex">
                                <input 
                                    type="email" 
                                    name="email_abo_confirm" 
                                    id="email_abo_confirm" 
                                    class="input_mail form-control"
                                    formControlName="email_abo_confirm"/>
                                <fa-icon 
                                    [icon]="close" 
                                    class="fa-icon-close" 
                                    size="sm" 
                                    title="Modifier les adresses email" 
                                    (click)="enableSpecificControl(EMAIL_INCLUSION);resetButtonStatePart2()"
                                    *ngIf="isButtonPart2Visible">
                                </fa-icon>
                            </div>
                            <em *ngIf="isInvalid('email_abo_confirm') && form4.get('email_abo_confirm')?.value?.length == 0">
                                <p>* Champ obligatoire</p>
                            </em>
                            <em *ngIf="isSubmitted && form4.get('email_abo_confirm')?.errors?.['pattern']">
                                <p>* Adresse Email non valide</p>
                            </em>
                            <em *ngIf="(form4.get('email_abo')?.valid || form4.get('email_abo_confirm')?.valid) && form4.hasError('emailAboMismatch')">
                                <p>* Les adresses email ne sont pas identiques</p>
                            </em>
                        </div>
                    }
            
                    <div>
                        <p><span class="obligatoire bold">*</span> Champs obligatoires</p>
                    </div>
                </section>
            </div>

            <div class="content-right order-1 col-6 bg-white">
                <section id="recap_representant">
                    <br>
                    <h5 class="font-weight-bold">Informations du représentant </h5>
            
                    <b> Civilité : </b>
                    <span class="text-break">{{civilite_representant}}</span>
                    <br>
                    <b>Nom : </b> 
                    <span class="text-break">{{nom_representant}}</span>
                    <br>
                    <b> Prénom : </b>
                    <span class="text-break">{{prenom_representant}}</span>
                    <br>
                    <b> Email : </b>
                    <span class="text-break">{{email_representant}}</span>
                    <br>
            
                    <div *ngIf="societe_organisme_representant">
                        <b> Société ou organisme : </b>
                        <span class="text-break">{{societe_organisme_representant}}</span>
                    </div>
                </section>
            </div>

        </div>
    </div>

    <div class="nav-buttons align-self-center" id="buttons">
        <button type="button" id="prevBtn" (click)="saveFormAndNavigate(-1)">Précédent</button>

        <input  
            type="button" 
            value="Je valide et passe à l'étape suivante" 
            class="submitBtn"
            (click)="saveFormAndNavigate(1)"
            [ngClass]="isFormValid() ? '' : 'disabled' "
            [disabled]="isFormValid() ? '' : 'disabled' "/>

        <!-- <button type="button" (click)="populateAbonneForm()">TEST ABONNE</button> -->
    </div>
}
