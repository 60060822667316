import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { NavigationService } from '../services/navigation/navigation.service';
import { StepBarComponent } from "../step-bar/step-bar.component";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faInfoCircle, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Params } from '@angular/router';
import { environment } from '../../environments/environment';
import { saisine } from '../form/form.component';
import { UserService } from '../services/user/user-service.service';
import { FormDataService } from '../services/form-data/form-data.service';
import { CryptoService } from '../services/crypto/crypto.service';
import { switchMap } from 'rxjs';


@Component({
    selector: 'app-form1',
    standalone: true,
    templateUrl: './form1.component.html',
    imports: [CommonModule, ReactiveFormsModule, StepBarComponent, FontAwesomeModule]
})

export class Form1Component 
{
  form1!: FormGroup
  currentPage!: number
  isErrorVisible: boolean = false
  info = faInfoCircle
  exclamation = faExclamation

  constructor
  (
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private formDataService: FormDataService,
    private cryptoService: CryptoService
  )
  {
    window.parent.postMessage('scrollUp', environment.PARENT_URL)

    sessionStorage.clear()

    this.activatedRoute.queryParams.subscribe((params:Params) => {

      if( params['new'] && params['new'] != environment.EXCLUDE_URL )
      {
        // sessionStorage.setItem("new", params['new'])
        this.getUserData(params['new'])
      }
    })
    
    this.form1 = new FormGroup({
      exclusions : new FormControl(false,[Validators.requiredTrue]),
    })
  }

  ngOnInit()
  {
    this.currentPage = this.navigationService.getCurrentPage()
  }

  getUserData(param:string)
  {
    this.cryptoService.decryptData(param).pipe(
      switchMap(decrypted => {
        return this.userService.getUserData(Number(decrypted))
      })
    ).subscribe(data => {
      sessionStorage.setItem("new", param)

      saisine['_societe_organisme'] = data[0].ENTREPRISE_REPRESENTANT ? data[0].ENTREPRISE_REPRESENTANT: data[0].ENTREPRISE
      saisine['_civilite'] = data[0].ID_CIVILITE_REPRESENTANT ? data[0].ID_CIVILITE_REPRESENTANT : data[0].ID_CIVILITE
      saisine['_nom'] = data[0].NOM_REPRESENTANT ? data[0].NOM_REPRESENTANT : data[0].NOM
      saisine['_prenom'] = data[0].PRENOM_REPRESENTANT ? data[0].PRENOM_REPRESENTANT : data[0].PRENOM
      saisine['_email'] = data[0].EMAIL_REPRESENTANT ? data[0].EMAIL_REPRESENTANT : data[0].EMAIL
  
      // saisine['_lien_abo'] = data[0].ID_TYPE_REPRESENTANT ? data[0].ID_TYPE_REPRESENTANT : 0

      // saisine['_type_contrat'] = data[0].ID_TYPE_CONTRAT ? data[0].ID_TYPE_CONTRAT : 0
      // saisine['_info_eau_potable'] = data[0].INFO_EAU ? data[0].INFO_EAU : ""
      // saisine['_info_assainissement_collectif'] = data[0].INFO_ASS ? data[0].INFO_ASS : ""
      saisine['_type_contrat'] = 0
      saisine['_info_eau_potable'] = ""
      saisine['_info_assainissement_collectif'] = ""
      
      // if(!representant)
      // {
      //   saisine['_adresse'] = data[0].ADRESSE || ""
      //   saisine['_cp'] = data[0].CODE_POSTAL || ""
      //   saisine['_ville'] = data[0].VILLE || ""
      //   saisine['_pays'] = data[0].ID_PAYS || 1
      // }

      saisine['_adresse_correspondance'] = (data[0].ID_TYPE_REPRESENTANT != 0 && data[0].ADRESSE_CORRESPONDANCE) ? data[0].ADRESSE_CORRESPONDANCE :  ""
      saisine['_cp_correspondance'] = (data[0].ID_TYPE_REPRESENTANT != 0 && data[0].CODE_POSTAL_CORRESPONDANCE) ? data[0].CODE_POSTAL_CORRESPONDANCE :  ""
      saisine['_ville_correspondance'] = (data[0].ID_TYPE_REPRESENTANT != 0 && data[0].VILLE_CORRESPONDANCE) ? data[0].VILLE_CORRESPONDANCE :  ""
      // saisine['_pays_correspondance'] = data[0].ID_PAYS_CORRESPONDANCE || 0
      // saisine['_complement_adresse_correspondance'] = data[0].COMPLEMENT_ADRESSE_HORS_FRANCE || ""

      saisine['_new_saisine_abonne'] = true

      this.formDataService.saveFormData(saisine)
    })
  }


  saveFormAndNavigate(n:number)
  {
    if (this.form1.invalid)
    {
      this.isErrorVisible = true
      return
    }
    else
    {
      this.isErrorVisible = false

      this.navigationService.redirect(n)
    }
  }
}
