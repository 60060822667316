import { Form } from "../../class/Form"

export const isFormValid = (obj:Form): boolean => {
    if( obj?.hasOwnProperty("_civilite_abo")
    &&
    obj?.hasOwnProperty("_lien_abo")
    &&
    obj?.hasOwnProperty("_nom_abo")
    &&
    obj?.hasOwnProperty("_prenom_abo")
    &&
    obj?.hasOwnProperty("_email_abo") 
    &&
    obj?.hasOwnProperty("_societe_organisme_abo")
    &&
    obj?.hasOwnProperty("_ref_dossier") 
    &&
    obj.hasOwnProperty("_civilite")
    &&
    obj.hasOwnProperty("_nom") 
    &&
    obj.hasOwnProperty("_prenom")
    &&
    obj.hasOwnProperty("_email")
    &&
    obj.hasOwnProperty("_societe_organisme")
    &&
    obj.hasOwnProperty("_type_contrat")
    &&
    obj.hasOwnProperty("_info_eau_potable")
    &&
    obj.hasOwnProperty("_info_assainissement_collectif")
    &&
    obj.hasOwnProperty("_litige")
    &&
    obj.hasOwnProperty("_attentes")
    &&
    obj.hasOwnProperty("_explication")
    &&
    obj.hasOwnProperty("_adresse")
    &&
    obj.hasOwnProperty("_cp")
    &&
    obj.hasOwnProperty("_ville")
    &&
    obj.hasOwnProperty("_pays")
    &&
    obj.hasOwnProperty("_adresse_correspondance")
    &&
    obj.hasOwnProperty("_cp_correspondance")
    &&
    obj?.hasOwnProperty("_ville_correspondance")
    &&
    obj?.hasOwnProperty("_pays_correspondance")
    &&
    obj?.hasOwnProperty("_complement_adresse_correspondance") 
    &&
    obj?.hasOwnProperty("_new_saisine_abonne") 
    ) return true
    else return false
}


export const formHasDefaultValue = (obj:any): boolean => {
    if( obj._civilite_abo == 0
    &&
    obj._lien_abo == 0
    &&
    obj._nom_abo == ""
    &&
    obj._prenom_abo == ""
    &&
    obj._email_abo == ""
    &&
    obj._societe_organisme_abo == ""
    &&
    obj._ref_dossier == ""
    &&
    obj._civilite == 0
    &&
    obj._nom == ""
    &&
    obj._prenom == ""
    &&
    obj._email == ""
    &&
    obj._societe_organisme == ""
    &&
    obj._type_contrat == 0
    &&
    obj._info_eau_potable == ""
    &&
    obj._info_assainissement_collectif == ""
    &&
    obj._litige == ""
    &&
    obj._attentes == ""
    &&
    obj._explication == ""
    &&
    obj._adresse == ""
    &&
    obj._cp == ""
    &&
    obj._ville == ""
    &&
    obj._pays == 1
    &&
    obj._adresse_correspondance == ""
    &&
    obj._cp_correspondance == ""
    &&
    obj._ville_correspondance == ""
    &&
    obj._pays_correspondance == 0
    &&
    obj._complement_adresse_correspondance ==  ""
    &&
    obj._new_saisine_abonne == false
    ) return true
    else return false
}


export const checkImportantValue = (obj:any): boolean => {
    if( 
    obj._nom_abo == ""
    ||
    obj._prenom_abo == ""
    ||
    obj._type_contrat == 0
    ||
    obj._litige == ""
    ||
    obj._attentes == ""
    ||
    obj._adresse == ""
    ||
    obj._cp == ""
    ||
    obj._ville == ""
    ) return true
    else return false
}
