import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";


// export function notZeroValidator(control: AbstractControl): ValidatorFn 
// {
//     return (control: AbstractControl): ValidationErrors | null => {
//         return control.value != 0 ? null : { notZero: true }
//     }
// }
export function notZeroValidator(control: AbstractControl)
{
    return control.value != 0 ? null : { notZero: true }
}
