import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { FormDataService } from '../services/form-data/form-data.service';
import { NavigationService } from '../services/navigation/navigation.service';
import { StepBarComponent } from "../step-bar/step-bar.component";
import { faInfoCircle, faExclamation, faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule, } from '@fortawesome/angular-fontawesome';
import { MailService } from '../services/mail/mail.service';
import { confirmEmailAboValidator, confirmEmailValidator, sameEmailValidator } from '../custom_validators/email-validator';
import { saisine } from '../form/form.component';
import { HelpersService } from '../services/helpers/helpers.service';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CryptoService } from '../services/crypto/crypto.service';
import { ScrollToTop } from '../helpers/decorator/afterEachNavigation';
import { removeHTMLTags } from '../helpers/functions/removeHTMLTags';
import { UserService } from '../services/user/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormService } from '../services/form/form.service';
import { InputListenerDirective } from '../directives/input-listener/input-listener.directive';
import { TrimStartDirective } from '../directives/trim-start/trim-start.directive';
import { environment } from '../../environments/environment';
import { notZeroValidator } from '../custom_validators/not-zero-validator';


@Component({
    selector: 'app-form4',
    standalone: true,
    templateUrl: './form4.component.html',
    imports: [CommonModule, ReactiveFormsModule, StepBarComponent, FontAwesomeModule, MatFormFieldModule, MatSelectModule, InputListenerDirective, TrimStartDirective]
})

export class Form4Component 
{
  form4!: FormGroup

  isEmailExist!:boolean
  isEmailAboExist!:boolean
  isActive!:boolean
  isSubmitted:boolean = false
  isAccountFormSubmitted:boolean = false
  isCheckEmail:boolean = false
  isAccountCreation!:boolean
  isButtonVisible: boolean = false
  isValidateEmailButtonVisible:boolean = true
  isButtonPart2Visible: boolean = false
  isValidateEmailButtonPart2Visible:boolean = true

  currentPage!: number
  info = faInfoCircle
  exclamation = faExclamation
  list_lien_abonne:{ID_TYPE_REPRESENTANT:number, TYPE_REPRESENTANT:string, ORDRE:number}[] = []
  list_mandat:number[] = [11, 16, 18] //Proche (membre de la famille, ami, ...) | Représentant légal (tutelle, curatelle, ...) | Propriétaire ou locataire
  listeCivilite:{ID_CIVILITE:number, CIVILITE:string}[] = []

  readonly inputLimit = 
  Object.freeze(
    new Map()
    .set("text_limit", 300)
    .set("societe_organisme", 150)
    .set("nom", 100)
    .set("prenom", 100)
    .set("email", 200)
  )
  readonly emailPattern = Object.freeze("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]+$")
  readonly mailErrorMessage = Object.freeze("Un problème est surevnu lors de l'envoi d'email. Veuillez réessayer plus tard ou utiliser le formulaire de contact de la Médiation de l'eau")
  readonly accountErrorMessage = Object.freeze("Un problème est surevnu lors de la création de votre espace personnel. Veuillez réessayer plus tard ou utiliser le formulaire de contact de la Médiation de l'eau")
  readonly verifMailMessage = Object.freeze("Vous pouvez dès à présent cliquer sur le bouton 'Créer mon espace personnel' pour finaliser votre inscription")
  readonly verifMailMessagePart2 = Object.freeze("Vous pouvez dès à présent cliquer sur le bouton 'Je valide et passe à l'étape suivante'")
  readonly accountAlreadyExists = Object.freeze("Cette adresse mail est déjà liée à un espace personnel. Veuillez vous y connecter")
  readonly accountIsNotActive = Object.freeze("Cette adresse mail est déjà liée à un espace personnel mais n'a pas encore été activée")
  readonly accountCreationDelay = Object.freeze("Vous avez déjà valider la création de votre espace personnel il y a peu de temps, veuillez attendre 15 minutes avant votre prochaine tentative")
  DISABLE_EXCLUSION:string[] = ["ref_dossier", "type_contrat", "info_eau_potable", "info_assainissement_collectif", "isAbo"]
  EMAIL_INCLUSION:string[] = ["email", "email_confirm", "email_abo", "email_abo_confirm"]
  close = faClose

  civilite_representant!:string
  societe_organisme_representant!:string
  nom_representant!:string
  prenom_representant!:string
  email_representant!:string

  lien_page_contact:string = environment.CONTACT_PAGE
  

  constructor
  (
    private formDataService: FormDataService,
    private navigationService: NavigationService,
    private helperService: HelpersService,
    private mailService: MailService,
    private cryptoService: CryptoService,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private formService: FormService,
  ) {}

  ngOnInit()
  {
    this.getListeCivilite()
    this.checkIfAccountCreation()

    this.isAccountCreation ? this.initForm() : this.initRepresentantAbonneForm()  

    this.getDataLienAbo()

    this.currentPage = this.navigationService.getCurrentPage()
  }


  initForm()
  {
    this.form4 = new FormGroup(
    {
      isAbo: new FormControl("0"),
      societe_organisme: new FormControl("", [Validators.maxLength(this.inputLimit.get('societe_organisme'))]),
      civilite : new FormControl(0, [Validators.required, notZeroValidator]),
      nom : new FormControl("", [Validators.required, Validators.maxLength(this.inputLimit.get('nom'))]),
      prenom : new FormControl("", [Validators.required, Validators.maxLength(this.inputLimit.get('prenom'))]),
      email : new FormControl("", [Validators.required, Validators.email, Validators.pattern(this.emailPattern), Validators.maxLength(this.inputLimit.get('email'))]),
      email_confirm : new FormControl("", [Validators.required, Validators.email, Validators.pattern(this.emailPattern), Validators.maxLength(this.inputLimit.get('email'))]),
    },
    {
      validators: [confirmEmailValidator("email","email_confirm")]
    })
  }

  initRepresentantAbonneForm()
  {
    this.form4 = new FormGroup(
    {
      // Ne pas supprimer, sert de référence pour sameEmailValidator
      email : new FormControl(saisine["_email"], [Validators.email, Validators.pattern(this.emailPattern), Validators.maxLength(this.inputLimit.get('email'))]),

      lien_abo : new FormControl(0, [Validators.required, notZeroValidator]),
      societe_organisme_abo: new FormControl(saisine["_societe_organisme_abo"], [Validators.maxLength(this.inputLimit.get('societe_organisme'))]),
      civilite_abo : new FormControl(saisine["_civilite_abo"], [Validators.required, notZeroValidator]),
      nom_abo : new FormControl(saisine["_nom_abo"], [Validators.required, Validators.maxLength(this.inputLimit.get('nom'))]),
      prenom_abo : new FormControl(saisine["_prenom_abo"], [Validators.required, Validators.maxLength(this.inputLimit.get('prenom'))]),
      email_abo : new FormControl(saisine["_email_abo"], [Validators.email, Validators.pattern(this.emailPattern), Validators.maxLength(this.inputLimit.get('email'))]),
      email_abo_confirm : new FormControl("", [Validators.email, Validators.pattern(this.emailPattern), Validators.maxLength(this.inputLimit.get('email'))]),
    },
    {
      validators: [sameEmailValidator("email", "email_abo")]
    })
  }


  // populateForm()
  // {
  //   this.form4.get("societe_organisme")?.setValue("AAA")
  //   this.form4.get("societe_organisme")?.updateValueAndValidity()
  //   this.form4.get("civilite")?.setValue(1)
  //   this.form4.get("civilite")?.updateValueAndValidity()
  //   this.form4.get("nom")?.setValue("AA")
  //   this.form4.get("nom")?.updateValueAndValidity()
  //   this.form4.get("prenom")?.setValue("A")
  //   this.form4.get("prenom")?.updateValueAndValidity()
  //   this.form4.get("email")?.setValue("u.sekpon@gmail.com")
  //   this.form4.get("email")?.updateValueAndValidity()
  //   this.form4.get("email_confirm")?.setValue("u.sekpon@gmail.com")
  //   this.form4.get("email_confirm")?.updateValueAndValidity()
  //   this.form4.updateValueAndValidity()
  // }

  // populateAbonneForm()
  // {
  //   this.form4.get("lien_abo")?.setValue(4)
  //   this.form4.get("societe_organisme_abo")?.setValue("CCC")
  //   this.form4.get("societe_organisme_abo")?.updateValueAndValidity()
  //   this.form4.get("civilite_abo")?.setValue(5)
  //   this.form4.get("civilite_abo")?.updateValueAndValidity()
  //   this.form4.get("nom_abo")?.setValue("C")
  //   this.form4.get("nom_abo")?.updateValueAndValidity()
  //   this.form4.get("prenom_abo")?.setValue("C")
  //   this.form4.get("prenom_abo")?.updateValueAndValidity()
  //   this.form4.get("email_abo")?.setValue("ultros92@hotmail.frr")
  //   this.form4.get("email_abo")?.updateValueAndValidity()
  //   this.form4.get("email_abo_confirm")?.setValue("ultros92@hotmail.fr")
  //   this.form4.get("email_abo_confirm")?.updateValueAndValidity()
  //   this.form4.updateValueAndValidity()
  // }

  checkIfAccountCreation()
  {
    const data = (sessionStorage.getItem("data") as string) || ""

    if(data.trim() == "" || !data) 
    {
      this.isAccountCreation = true
    }
    else
    {
      this.cryptoService.decryptData(data).subscribe(decrypted => {
        if(data && Number.isInteger(parseInt(decrypted)))
        {
          this.setValueRepresentant()

          this.form4.get('email_abo')?.valueChanges.subscribe(data => {
            if(data.length == 0)
            {
              this.formService.setValueControls([{control:"email_abo_confirm", value:""}], this.form4)
              this.formService.setValidators([{control:"email_abo_confirm", validators:[]}], this.form4)
            }
            else
            {
              this.formService.setValidators([{control:"email_abo_confirm", validators:[Validators.required]}], this.form4)
              this.formService.setValidatorsForm([sameEmailValidator("email", "email_abo"), confirmEmailAboValidator("email_abo", "email_abo_confirm")], this.form4)
            }
          })

          return this.isAccountCreation = false
        }
        else
        {
          return this.isAccountCreation = true
        }
      })
    }
  }

  eachWordUpperCase(value:string, form_element:string, form:FormGroup)
  {
    form.get(form_element)?.setValue
    (
      value
        ?.toLowerCase()
        ?.replace(/(?:^|\s|-)\S/g, (match) => match.toUpperCase())
        ?.trimStart()
    )
    return form.get(form_element)?.updateValueAndValidity()
  }

  // GET DATA
  getListeCivilite()
  {
    this.formService.getListeCivilite().subscribe(data => {
      this.listeCivilite = data
    })
  }

  async getDataLienAbo()
  {
    (await this.helperService.getListLienAbonne()).subscribe(data => {
      for (const lien_abo of data) 
      {
        if(lien_abo.ORDRE != 2)
        {
          data.push({ID_TYPE_REPRESENTANT:-1, TYPE_REPRESENTANT:"--- Mandat / pouvoir obligatoire dans les cas ci-dessous : ---", ORDRE:1})
          break
        }
      }

      this.list_lien_abonne = data.sort((a, b) => b.ORDRE - a.ORDRE)
    })
  }
  // FIN GET DATA


  // TOGGLE FORM
  enableSpecificControl(EMAIL_INCLUSION:string[])
  {
    this.formService.enableSpecificControl(EMAIL_INCLUSION, this.form4)
  }

  resetButtonState()
  {
    this.isButtonVisible = false
    this.isValidateEmailButtonVisible = true
  }

  resetButtonStatePart2()
  {
    this.isButtonPart2Visible = false
    this.isValidateEmailButtonPart2Visible = true
  }
  // FIN TOGGLE FORM


  // UPDATE FORM
  setValueRepresentant()
  {
    this.civilite_representant = saisine['_civilite_string']
    this.societe_organisme_representant = saisine['_societe_organisme']
    this.nom_representant = saisine['_nom']
    this.prenom_representant = saisine['_prenom']
    this.email_representant = saisine['_email']
  }

  resetStateForm(form:FormGroup)
  {
    this.isSubmitted = false
    this.isAccountFormSubmitted = false
    form.markAsPristine()
    form.markAsUntouched()
  }
  // FIN UPDATE FORM

  // STATE FROM
  isInvalid(controlName: string)
  {
    const control = this.form4.get(controlName)
    return control?.invalid && (control.dirty || control.touched)
  }

  isFormValid()
  { 
    return this.form4.valid
  }

  getErrorsForm()
  {
    if(!environment.production)
    {
      // Récupère toutes les erreurs du formulaire 
      console.log(this.form4)
      Object.keys(this.form4.controls).forEach(key => {
        const controlErrors = this.form4.get(key)?.errors
        if (controlErrors != null) 
        {
          Object.keys(controlErrors).forEach(keyError => {
            console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError])
          })
        }
      })
    }
  }
  // FIN STATE FROM

  // MAIL 
  checkEmail(email:string)
  {
    this.isCheckEmail = false
    this.isEmailExist = false
    this.isActive = false

    const email_confirm = this.form4.get("email_confirm")?.value
      
    if(email !== email_confirm)
    {
      this.isCheckEmail = true
      this.form4.markAllAsTouched()
      this.formService.setErrorsForm([{control: "email", value:{emailMismatch: true}}, {control: "email_confirm", value:{emailMismatch: true}}], this.form4)
      return
    }

    this.mailService.checkEmail(email).subscribe(data => {
      this.isCheckEmail = true

      this.isEmailExist = data[0].emailExist 
      this.isActive = data[0].isActive

      if(!environment.production)
      {
        console.log({isEmailExist:this.isEmailExist, isActive:this.isActive})
      }

      if(!this.isEmailExist && !this.isActive)
      {
        this.isButtonVisible = true
        this.isValidateEmailButtonVisible = false
        this.formService.disableSpecificControl(this.EMAIL_INCLUSION, this.form4)
        this.snackBar.open(this.verifMailMessage, "J'ai compris", {
          duration: 5000,
        })
      }
      else
      {
        this.isButtonVisible = false
        this.isValidateEmailButtonVisible = true
        this.formService.enableSpecificControl(this.EMAIL_INCLUSION, this.form4)
      }
    })
  }

  checkEmailAbo(email:string)
  {
    this.isCheckEmail = false
    this.isEmailAboExist = false

    const email_confirm = this.form4.get("email_abo_confirm")?.value
      
    if(email !== email_confirm)
    {
      this.isCheckEmail = true
      this.form4.markAllAsTouched()
      this.formService.setErrorsForm([{control: "email_abo", value:{emailAboMismatch: true}}, {control: "email_abo_confirm", value:{emailAboMismatch: true}}], this.form4)
      return
    }

    this.mailService.checkEmail(email).subscribe(data => {
      this.isCheckEmail = true

      this.isEmailAboExist = data[0].emailExist 

      if(!this.isEmailAboExist)
      {
        this.isButtonPart2Visible = true
        this.isValidateEmailButtonPart2Visible = false
        this.formService.disableSpecificControl(this.EMAIL_INCLUSION, this.form4)
        this.snackBar.open(this.verifMailMessagePart2, "J'ai compris", {
          duration: 5000,
        })
      }
      else
      {
        this.isButtonPart2Visible = false
        this.isValidateEmailButtonPart2Visible = true
        this.formService.enableSpecificControl(this.EMAIL_INCLUSION, this.form4)
      }
    })
  }

  resendMail(form:FormGroup)
  {
    if(form.get('email')?.value.trim() == "")
    {
      alert("L'adresse email est vide")
      return
    }
    if(form.get('email')?.value.trim().length > 0 && form.get('email')?.errors?.['pattern'])
    {
      alert("L'adresse email n'est pas valide")
      return
    }
    const email = form.get('email')?.value.trim()
    
    this.mailService.resendMailAccountActivation(email).subscribe(response => {
      let message

      if( response == 1 ) // OK
      {
        message = "Vous allez recevoir un mail d'activation de votre espace personnel dans quelques instants ..."
      }
      else if( response == 2 )
      {
        message = this.mailErrorMessage
      }
      else if( response == 3 )
      {
        message = "L'adresse email renseignée n'existe pas ou plus"        
      }
      else
      {
        message = this.mailErrorMessage
      }

      this.snackBar.open(message, "J'ai compris", {
        duration: 5000,
      })
    })
  }
  // FIN MAIL 

  createAccount()
  {
    this.isAccountFormSubmitted = true
    this.isButtonVisible = false

    this.getErrorsForm()

    if (this.form4.invalid) 
    {
      if(!environment.production) console.log("A")
      this.isButtonVisible = true
      this.form4.markAllAsTouched()
      return
    }
    else
    {
      if(!environment.production) console.log("B")
      this.isButtonVisible = true
      this.endCreationAccount()
    }
  }

  endCreationAccount()
  {
    const form = this.form4.getRawValue() // Utilisation de getRawValue pour récupérer les valeurs des champs disabled

    delete form['email_confirm']
    
    const isRepresentant = this.form4.get("isAbo")?.value == "1" ? true : false 
    const id_profil = isRepresentant ? 5 : 3

    const user = 
    {
      societe_organisme:form.societe_organisme,
      civilite:parseInt(form.civilite),
      nom:form.nom,
      prenom:form.prenom,
      email:form.email,
      id_profil:id_profil,
      id_type_abonne:form.societe_organisme.length > 0 ? 2 : 1,
      id_type_representant:0
    }

    this.userService.createUser({user}).subscribe(data => {
      this.isButtonVisible = true

      if(data.user.errorCode == 1)
      {
        return this.snackBar.open(this.accountAlreadyExists, "J'ai compris")
      }
      else if(data.user.errorCode == 2)
      {
        return this.snackBar.open(this.accountIsNotActive, "J'ai compris")
      }
      else if(data.user.errorCode == 3)
      {
        return this.snackBar.open(this.accountCreationDelay, "J'ai compris")
      }

      if(data.user.statusCode != 200)
      {
        this.snackBar.open(this.mailErrorMessage, "J'ai compris", {
          duration: 5000,
        })
        return
      }

      if(!data?.user?.id_utilisateur)
      {
        this.snackBar.open(this.accountErrorMessage, "J'ai compris", {
          duration: 5000,
        })
        return
      }

      return this.navigationService.redirectTo("formulaire_saisine/creation-compte")
    })
  }

  setValueSaisine()
  {
    const form = this.form4.getRawValue()

    saisine['_lien_abo'] = parseInt(form.lien_abo)
    saisine['_societe_organisme_abo'] = removeHTMLTags(form.societe_organisme_abo)
    saisine['_civilite_abo'] = parseInt(form.civilite_abo)
    saisine['_nom_abo'] = removeHTMLTags(form.nom_abo)
    saisine['_prenom_abo'] = removeHTMLTags(form.prenom_abo)
    saisine['_email_abo_represente'] = removeHTMLTags(form.email_abo)

    this.formDataService.saveFormData(saisine)
  }

  @ScrollToTop()
  async saveFormAndNavigate(n:number)
  {
    this.resetStateForm(this.form4)

    this.getErrorsForm()

    if( n === -1 ) return this.navigationService.redirect(n)
    
    this.isSubmitted = true

    this.form4.markAllAsTouched()

    if (this.form4.invalid)
    {
      this.form4.controls['civilite'].markAsTouched()
      this.form4.controls['nom'].markAsTouched()
      this.form4.controls['prenom'].markAsTouched()
      this.form4.controls['email'].markAsTouched()
      return
    }
      
    this.setValueSaisine()

    this.navigationService.redirect(n)
  }
}

