<app-step-bar [step]="currentPage"></app-step-bar>

<div class="tab tab8">
    <h2>Données enregistrées</h2>
    
    <p>Vous pouvez vous connecter à votre <a class="text-bold" href="{{lien_espace_abonne}}" target="_blank">espace personnel</a> pour consulter votre dossier.</p>

    <br>
    <p>
        Pour toutes demande d'informations, veuillez nous laissez un message via le formulaire de contact sur le site de la Médiation de l'eau.

        <br>
        <br>
        <a class="text-bold" href="assets/files/MDE_procedure_formulaire_de_contact.pdf" target="_blank" download id="procedure">La procédure pour accéder au formulaire de contact est disponible en cliquant ici</a>
    </p>
</div>




