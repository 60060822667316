import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class MailService 
{
  private readonly HOST:string  = environment.BASE_URL

  constructor(
    private httpClient: HttpClient
  ) { }

  async sendRequest(email_object:object):Promise<Observable<any>>
  {
    const url  = `${this.HOST}/mail`

    return this.httpClient.post(url,email_object)
  }

  checkEmail(email:string):Observable<{emailExist:boolean, isActive:boolean}[]>
  {
    const url  = `${this.HOST}/mail`

    return this.httpClient.get<{emailExist:boolean, isActive:boolean}[]>(url,
      {
        params : 
        {
          email: email,
        }
      }
    )
  }

  resendMailAccountActivation(email:string):Observable<number>
  {
    const url  = `${this.HOST}/account_activation`

    return this.httpClient.get<number>(url,
      {
        params : 
        {
          email:email
        }
      }
    )
  }
}
