import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { NavigationService } from '../services/navigation/navigation.service';
import { StepBarComponent } from "../step-bar/step-bar.component";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faInfoCircle, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Params } from '@angular/router';
import { environment } from '../../environments/environment';
import { saisine } from '../form/form.component';
import { UserService } from '../services/user/user.service';
import { FormDataService } from '../services/form-data/form-data.service';
import { CryptoService } from '../services/crypto/crypto.service';
import { switchMap } from 'rxjs';


@Component({
    selector: 'app-form1',
    standalone: true,
    templateUrl: './form1.component.html',
    imports: [CommonModule, ReactiveFormsModule, StepBarComponent, FontAwesomeModule]
})

export class Form1Component 
{
  form1!: FormGroup
  currentPage!: number
  isErrorVisible: boolean = false
  info = faInfoCircle
  exclamation = faExclamation

  constructor
  (
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private formDataService: FormDataService,
    private cryptoService: CryptoService
  )
  {
    window.parent.postMessage('scrollUp', environment.PARENT_URL)

    sessionStorage.clear()

    this.getParams()
    
    this.form1 = new FormGroup({
      exclusions : new FormControl(false,[Validators.requiredTrue]),
    })
  }

  ngOnInit()
  {
    this.currentPage = this.navigationService.getCurrentPage()
  }

  getParams()
  {
    this.activatedRoute.queryParams.subscribe((params:Params) => {
      if( params['data'] && params['data'].trim().length > 0 && params['data'] != environment.EXCLUDE_URL )
      {
        this.getUserData(params['data'])
      }
    })
  }

  getUserData(param:string)
  {
    if(!param && param.trim().length == 0) return

    this.cryptoService.decryptData(param).pipe(
      switchMap(decrypted => {
        return this.userService.getUserData(Number(decrypted))
      })
    ).subscribe(data => {
      sessionStorage.setItem("data", param)

      const id_profil = data[0].ID_PROFIL

      if(id_profil == 3)
      {
        saisine['_societe_organisme_abo'] = data[0]?.ENTREPRISE || ""
        saisine['_civilite_abo'] = data[0]?.ID_CIVILITE || 0
        saisine['_nom_abo'] = data[0]?.NOM || ""
        saisine['_prenom_abo'] = data[0]?.PRENOM || ""
        saisine['_email_abo'] = data[0]?.EMAIL || ""
        saisine['_id_abonne'] = data[0]?.ID_UTILISATEUR
        saisine['_id_representant'] = 0
      }

      if(id_profil == 5)
      {
        saisine['_societe_organisme'] = data[0]?.ENTREPRISE|| ""
        saisine['_civilite_string'] = data[0].ID_CIVILITE_STRING || ""
        saisine['_civilite'] = data[0].ID_CIVILITE || 0
        saisine['_nom'] = data[0]?.NOM || ""
        saisine['_prenom'] = data[0]?.PRENOM || ""
        saisine['_email'] = data[0]?.EMAIL || ""
        saisine['_id_abonne'] = 0
        saisine['_id_representant'] = data[0]?.ID_UTILISATEUR
      }


      saisine['_adresse_correspondance'] = data[0]?.ADRESSE_CORRESPONDANCE || ""
      saisine['_cp_correspondance'] = data[0]?.CODE_POSTAL_CORRESPONDANCE ||  ""
      saisine['_ville_correspondance'] = data[0]?.VILLE_CORRESPONDANCE ||  ""

      saisine['_new_saisine_abonne'] = true
      saisine['_id_join'] = data[0]?.ID_JOIN

      this.formDataService.saveFormData(saisine)

      const step = id_profil == 5 ? 4 : 5
      this.goToStep(step)
    })
  }

  goToStep(index:number)
  {
    this.navigationService.redirectTo(`formulaire_saisine/etape_${index}`)
  }

  saveFormAndNavigate(n:number)
  {
    if (this.form1.invalid)
    {
      this.isErrorVisible = true
      return
    }
    else
    {
      this.isErrorVisible = false

      this.navigationService.redirect(n)
    }
  }
}
