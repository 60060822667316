import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationService } from '../services/navigation/navigation.service';
import { StepBarComponent } from "../step-bar/step-bar.component";
import { FormService } from '../services/form/form.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UploadService } from '../services/upload/upload.service';
import { MailService } from '../services/mail/mail.service';
import { saisine, file_list } from '../form/form.component';
import { ScrollToTop } from '../helpers/decorator/afterEachNavigation';
import { removeHTMLTags } from '../helpers/functions/removeHTMLTags';
import { checkImportantValue, formHasDefaultValue, isFormValid } from '../helpers/functions/FormValid';
import { checkValueForm } from '../helpers/functions/CheckValueForm';
import { Form } from '../class/Form';


@Component({
    selector: 'app-form7',
    standalone: true,
    templateUrl: './form7.component.html',
    imports: [CommonModule, StepBarComponent]
})

export class Form7Component 
{
  date:Date = new Date()
  localeDateString = this.date.toLocaleDateString().replaceAll("/","-")
  isValid!: boolean
  civilite!: number
  civilite_string!:string
  nom!: string
  prenom!: string
  email!: string
  societe_organisme!:string
  type_contrat!: number
  type_contrat_string!: string
  info_eau_potable!: string
  info_assainissement_collectif!: string
  civilite_abo!: number
  civilite_abo_string!:string
  lien_abo!: number
  lien_abo_string!:string
  nom_abo!: string
  prenom_abo!: string
  email_abo!: string
  societe_organisme_abo!: string
  ref_dossier!: string
  litige!:string
  attentes!:string
  adresse!:string
  cp!:string
  ville!:string
  pays!:number
  adresse_correspondance!:string
  cp_correspondance!:string
  ville_correspondance!:string
  pays_correspondance!:number
  pays_correspondance_string!:string
  complement_adresse_correspondance!:string
  explication!:string
  file1_list: File[] = []
  file2_list: File[] = []
  file3_list: File[] = []
  file4_list: File[] = []
  file5_list: File[] = []
  file6_list: File[] = []
  currentPage!: number
  isSubmitted:boolean = false

  constructor
  (
    private formService: FormService,
    private navigationService: NavigationService,
    private uploadService: UploadService,
    private mailService: MailService,
    private snackBar: MatSnackBar
  )
  {
    this.alertRedirect()
  }

  async ngOnInit()
  {    
    this.checkPreviousPageForm(saisine, 4)
    this.checkPreviousPageForm(saisine, 5)

    this.nom = removeHTMLTags(saisine["_nom"])
    this.prenom = removeHTMLTags(saisine["_prenom"])
    this.email = removeHTMLTags(saisine["_email"])
    this.societe_organisme =  removeHTMLTags(saisine["_societe_organisme"])
    this.civilite = saisine["_civilite"]
    
    this.civilite_abo = saisine["_civilite_abo"]
    this.lien_abo = saisine["_lien_abo"]
    this.nom_abo = removeHTMLTags(saisine["_nom_abo"])
    this.prenom_abo = removeHTMLTags(saisine["_prenom_abo"])
    this.email_abo = removeHTMLTags(saisine["_email_abo"])
    this.societe_organisme_abo = removeHTMLTags(saisine["_societe_organisme_abo"])
    this.ref_dossier = removeHTMLTags(saisine["_ref_dossier"])

    this.type_contrat = saisine["_type_contrat"]
    this.info_eau_potable = removeHTMLTags(saisine["_info_eau_potable"])
    this.info_assainissement_collectif = removeHTMLTags(saisine["_info_assainissement_collectif"])

    this.litige = removeHTMLTags(saisine["_litige"])
    this.attentes = removeHTMLTags(saisine["_attentes"])

    this.adresse = removeHTMLTags(saisine["_adresse"])
    this.cp = removeHTMLTags(saisine["_cp"])
    this.ville = removeHTMLTags(saisine["_ville"])
    this.pays = saisine["_pays"]

    this.adresse_correspondance = removeHTMLTags(saisine["_adresse_correspondance"])
    this.cp_correspondance = removeHTMLTags(saisine["_cp_correspondance"])
    this.ville_correspondance = removeHTMLTags(saisine["_ville_correspondance"])
    this.pays_correspondance = saisine["_pays_correspondance"]
    this.complement_adresse_correspondance = removeHTMLTags(saisine["_complement_adresse_correspondance"])

    this.explication = removeHTMLTags(saisine["_explication"])

    this.file1_list = file_list["file1_list"]
    this.file2_list = file_list["file2_list"]
    this.file3_list = file_list["file3_list"]
    this.file4_list = file_list["file4_list"]
    this.file5_list = file_list["file5_list"]
    this.file6_list = file_list["file6_list"]

    this.getDataCivilite(this.civilite)
    this.getDataCiviliteAbo(this.civilite_abo)
    this.getDataLienAbo(this.lien_abo)
    this.getDataTypecontrat(this.type_contrat)
    this.getDataPays(this.pays_correspondance)

    this.currentPage = this.navigationService.getCurrentPage()
  }

  checkPreviousPageForm(form:Form, etape:number)
  {
    const isFormValid = checkValueForm({form:form, etape:etape})
    if(isFormValid)
    {
      console.log(`form ${etape} is valid`)
      return
    }
    else
    {
      return this.navigationService.redirectTo(`formulaire_saisine/etape_${etape}`)
    }
  }
  
  getDataCivilite(id_civilite:number)
  {
    this.formService.getCivilite(id_civilite).subscribe(data => {
      this.civilite_string = data[0].CIVILITE
    })
  }
  
  getDataCiviliteAbo(id_civilite:number)
  {
    this.formService.getCivilite(id_civilite).subscribe(data => {
      this.civilite_abo_string = data[0]?.CIVILITE
    })
  }

  getDataLienAbo(id_lien_abo:number)
  {
    if( id_lien_abo != 0 )
    {
      this.formService.getLienAbo(id_lien_abo).subscribe(data => {
        this.lien_abo_string = data[0]?.TYPE_REPRESENTANT
      })
    }
  }

  getDataTypecontrat(id_type_contrat:number)
  {
    if( id_type_contrat != 0 )
    {
      this.formService.getTypeContrat(id_type_contrat).subscribe(data => {
        this.type_contrat_string = data[0].TYPE_CONTRAT
      })
    }
  }

  getDataPays(id_pays:number)
  {
    if( id_pays != 0 && id_pays != 1 )
    {
      this.formService.getPays(id_pays).subscribe(data => {
        this.pays_correspondance_string = data[0].NOM_PAYS
      })
    }
  }

  alertRedirect()
  {
    if(
      file_list["file1_list"].length == 0
      && file_list["file2_list"].length == 0
      && file_list["file3_list"].length == 0
      && file_list["file4_list"].length == 0
      && file_list["file5_list"].length == 0
    )
    {
      alert("Certains documents semblent manquer. Vous allez être redirigé à l'étape précédente pour joindre les documents demandés")
      this.navigationService.redirect(-1)
    }
  }

  @ScrollToTop()
  saveFormAndNavigate(n:number)
  {
    if( n === -1 ) 
    {
      this.navigationService.redirect(n)
      return
    }

    this.alertRedirect()

    this.submitForm()
  }

  async submitForm()
  {
    this.isSubmitted = true
    
    const formData = new FormData()
    
    const form = saisine

    const array_files = [];

    (file_list["file1_list"]as any).contenu = "copie reclamation";
    (file_list["file2_list"]as any).contenu = "copie factures";
    (file_list["file3_list"]as any).contenu = "reponse service";
    (file_list["file4_list"]as any).contenu = "indication fuite";
    (file_list["file5_list"]as any).contenu = "autres documents";
    (file_list["file6_list"]as any).contenu = "justificatif representant";

    array_files.push(file_list["file1_list"])
    array_files.push(file_list["file2_list"])
    array_files.push(file_list["file3_list"])
    array_files.push(file_list["file4_list"])
    array_files.push(file_list["file5_list"])
    array_files.push(file_list["file6_list"])


    array_files.map((file_list:any, index:number) => {
      if( file_list.length > 0 )
      {
        const contenu = file_list.contenu

        file_list.forEach((e: string | Blob) => {
          formData.append( `files_${index}`, e )
        })
        formData.append( `contenu_${index}`, contenu )
      }
    })

    if( !isFormValid(form) ) 
      {
        alert("Certains champs du formulaire ne sont pas présents ou ne sont pas renseignés, veuillez recommencer le processus de saisine")
        this.navigationService.redirectToRoot() 
        return
      }

      if( formHasDefaultValue(form) ) 
      {
        alert("Certains champs du formulaire ne sont pas présents ou ne sont pas renseignés, veuillez recommencer le processus de saisine")
        this.navigationService.redirectToRoot() 
        return
      }

      if( checkImportantValue(form) ) 
      {
        alert("Certains des éléments nécessaire à l'enregistrement de la saisine ne sont pas présents, veuillez recommencer le processus de saisine")
        this.navigationService.redirectToRoot() 
        return
      }

    this.alertRedirect()

    const snackbarWait = this.snackBar.open("Veuillez patienter ...", "Ok")

    for (const key in form) 
    {
      if (Object.prototype.hasOwnProperty.call(form, key)) 
      {
        const element = form[key]
        formData.append( `${key}`, element.toString() )
      }
    }

    ;(await
      this.formService.sendForm(formData)).subscribe({
      next: async (response) => {
     
        if(response == 1)
        {
          snackbarWait.dismiss()

          snackbarWait.afterDismissed().subscribe(() => {
            this.navigationService.redirect(1) 
          })
        }
        else if(response == 3)
        {
          const msg = "Connexion au serveur impossible. Veuillez réessayer plus tard ou utiliser le formulaire de contact de la Médiation de l'eau"
          return this.errorSnackbar(msg)
        }
        else if(response == 4)
        {
          const msg = "Cette adresse mail est déjà liée à un espace abonné"
          return this.errorSnackbar(msg)
        }
        else if(response == 5)
        {
          const msg = "Cette adresse mail est déjà liée a un compte actif"
          return this.errorSnackbar(msg)
        }
        else
        {
          return this.errorSnackbar()
        }
      }, 
      error: (e) => 
      {    
        console.log(e)

        return this.errorSnackbar()
      }
    })
  }

  errorSnackbar(msg:string = "")
  {
    const message = (msg && msg.trim().length > 0) ? msg : "Un problème est survenu lors de l'enregistrement du formulaire. Veuillez réessayer plus tard ou utiliser le formulaire de contact de la Médiation de l'eau"

    const snackbarError = this.snackBar.open(message, "J'ai compris", {
      duration: 5000,
    })

    snackbarError.afterDismissed().subscribe(() => {
      this.navigationService.redirectToSite()
    })
  }
}
