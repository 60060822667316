import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, retry, timer } from 'rxjs';
import { environment } from '../../../environments/environment';
import { FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})

export class FormService 
{
  private readonly HOST:string = environment.BASE_URL

  constructor(
    private httpClient: HttpClient
  ) { }

  async sendForm(form:FormData):Promise<Observable<Object>>
  {
    const url  = `${this.HOST}/form`

    return this.httpClient.post(url, form)
  }

  getListeCivilite():Observable<{ID_CIVILITE:number, CIVILITE:string}[]>
  {
    const url  = `${this.HOST}/liste-civilite`

    return this.httpClient.get<{ID_CIVILITE:number, CIVILITE:string}[]>(url)
      .pipe(
        retry({
          count: 3,
          delay: (error, retryCount) => timer(retryCount * 1000), // planificateur exponentiel: 1s, 2s, 3s, etc.
        })
      )
  }

  getDataForm(idList:{id_civilite:number, id_civilite_abo:number, id_lien_abo:number, id_type_contrat:number, id_pays:number}):Observable<{CIVILITE:string, CIVILITE_ABO:string, TYPE_REPRESENTANT:string, TYPE_CONTRAT:string, NOM_PAYS:string}[]>
  {
    const url = `${this.HOST}/data_form`

    return this.httpClient.post<{CIVILITE:string, CIVILITE_ABO:string, TYPE_REPRESENTANT:string, TYPE_CONTRAT:string, NOM_PAYS:string}[]>(url, idList)
  }

    // TOGGLE FORM
    disableForm(DISABLE_EXCLUSION:string[], form:FormGroup)
    {
      for( const control in form.controls)
      {
        if(!DISABLE_EXCLUSION.includes(control))
        {
          form.get(control)?.disable()
        }
      }
    }
  
    disableSpecificControl(INCLUSION:string[], form:FormGroup)
    {
      for( const control in form.controls)
      {
        if(INCLUSION.includes(control))
        {
          form.get(control)?.disable()
        }
      }
    }
  
    enableSpecificControl(INCLUSION:string[], form:FormGroup)
    {
      for( const control in form.controls)
      {
        if(INCLUSION.includes(control))
        {
          form.get(control)?.enable()
        }
      }
    }
    // FIN TOGGLE FORM
  
  
    // UPDATE FORM
    setErrorsForm(array:{control:string, value:ValidationErrors}[], form:FormGroup)
    {
      array.forEach(element => {
        form.get(element.control)?.setErrors(element.value)
        form.get(element.control)?.updateValueAndValidity()
      })
      form.updateValueAndValidity()
    }
  
    setValueControls(array:{control:string, value:string|number}[], form:FormGroup)
    {
      array.forEach(element => {
        form.get(element.control)?.setValue(element.value)
        form.get(element.control)?.updateValueAndValidity()
      })
      form.updateValueAndValidity()
    }
  
    setValidators(array:{control:string, validators:ValidatorFn[]}[], form:FormGroup)
    {
      array.forEach(element => {
        form.get(element.control)?.setValidators(element.validators)
        form.get(element.control)?.updateValueAndValidity()
      })
      form.updateValueAndValidity()
    }
  
    setValidatorsForm(validators:ValidatorFn[], form:FormGroup)
    {
      form?.setValidators(validators)
      form?.updateValueAndValidity()
    }
  
    clearValidators(controls:string[], form:FormGroup)
    {
      controls.forEach(control => {
        form.get(control)?.clearValidators()
        form.get(control)?.updateValueAndValidity()
      })
      form.updateValueAndValidity()
    }
  
    clearValidatorsForm(form:FormGroup)
    {
      form.clearValidators()
      form.updateValueAndValidity()
    }
}
