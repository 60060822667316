import { Directive, HostListener, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[input[type="text"], input[type="email"], input[type="password"], input[type="search"], textarea]',
  standalone: true
})
export class TrimStartDirective 
{
  constructor
  (
    @Optional() @Self() private ngControl: NgControl,
  ) {}

  @HostListener('input', ['$event'])
  onChange(event: Event)
  {
    const target = (event.target as HTMLInputElement)
    const value = target.value
    
    const cleaned_value = value.trimStart()

    // Si il s'agit d'un controle d'un formulaire reactive form, on met à jour la valeur du champ
    if (this.ngControl && this.ngControl.control)
    {
      this.ngControl.control.setValue(cleaned_value)
      this.ngControl.control.updateValueAndValidity()
      this.ngControl.control.markAsDirty()
      this.ngControl.control.markAsTouched()
    }
    else
    {
      (event.target as HTMLInputElement).value = cleaned_value
    }
  }
}
