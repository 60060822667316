import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function sameEmailValidator(emailControlName: string, otherEmailControlName: string): ValidatorFn
{
  return (control: AbstractControl): ValidationErrors | null => {
    const email_1 = control.get(emailControlName)?.value
    const email_2 = control.get(otherEmailControlName)?.value

    if( email_1.trim() == "" && email_2.trim() == "")
    {
      return null
    }

    // Compare the values
    if (email_1 == email_2) 
    {
      // Return an error object with a key for the error and a value of true
      return { 'sameEmailMismatch': true }
    }

    // If validation passes, return null
    return null
  }
}

export function confirmEmailValidator(emailControlName: string, confirmEmailControlName: string): ValidatorFn
{
  return (control: AbstractControl): ValidationErrors | null => {
    const email = control.get(emailControlName)?.value
    const confirmEmail = control.get(confirmEmailControlName)?.value

    // Compare the values
    if (email !== confirmEmail) 
    {
      // Return an error object with a key for the error and a value of true
      return { 'emailMismatch': true }
    }

    // If validation passes, return null
    return null
  }
}

export function confirmEmailAboValidator(emailControlName: string, confirmEmailControlName: string): ValidatorFn
{
  return (control: AbstractControl): ValidationErrors | null => {
    const email = control.get(emailControlName)?.value
    const confirmEmail = control.get(confirmEmailControlName)?.value

    // Compare the values
    if (email !== confirmEmail) 
    {
      // Return an error object with a key for the error and a value of true
      return { 'emailAboMismatch': true }
    }

    // If validation passes, return null
    return null
  }
}